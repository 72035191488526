@charset "utf-8";

.bibsonomy {
    #tags {
        //    margin-top:-20px;
    }

    #entrycount {}

    .item {
        margin-bottom: 20px;
    }

    .year {}

    .yearTag {
        font-weight: bold;
        font-style: italic;
        margin-top: 30px;
        margin-bottom: 5px;
    }

    .bibtex {
        //font-family: Courier, sans-serif;
        font-size: small;
        padding: 5px;
        border: dotted 1px;
        margin: 10px 5px;
        white-space: pre-wrap;
        max-width: 800px;
    }

    .abstract {
        padding: 5px;
        border: dotted 1px;
        max-width: 800px;
        margin: 10px 5px;
    }

    .pubtitle {
        font-weight: bold;
        color: $color-publication-title;
    }

    .pubbooktitle {
        font-style: italic;
    }

    .pubjournal {
        font-style: italic;
    }

    .pubauthors {}

    .abstractLink {}

    .bibtexLink {}

    .bibInfo {
        font-style: italic;
    }

    .fileLink {}

    #loadingImg {
        margin: 20px;
    }

    a:link.acmAuthorized,
    a:visited.acmAuthorized {
        color: rgba(106, 195, 62, 1.0);
    }

    a:link.acmUnAuthorized,
    a:visited.acmUnAuthorized {
        /*color: rgba(217, 58, 60, 1.0);*/
    }



    a.acmUnAuthorized,
    a.acmAuthorized {
        outline: none;
    }

    a.acmUnAuthorized strong,
    a.acmAuthorized strong {
        line-height: 30px;
    }

    a.acmUnAuthorized:hover,
    a.acmAuthorized:hover {
        text-decoration: none;
    }

    a.acmUnAuthorized span,
    a.acmAuthorized span {
        z-index: 10;
        display: none;
        padding: 14px 20px;
        margin-top: -30px;
        margin-left: 28px;
        width: 303px;
        line-height: 16px;
    }

    a.acmUnAuthorized:hover span {
        display: inline;
        position: absolute;
        color: #111;
        border: 1px solid #DCA;
        background: #FAC9C9;
    }

    a.acmAuthorized:hover span {
        display: inline;
        position: absolute;
        color: #111;
        border: 1px solid #DCA;
        background: #E3FFDF;
    }

    /*CSS3 extras*/
    a.acmUnAuthorized span,
    a.acmAuthorized span {
        border-radius: 4px;
        box-shadow: 5px 5px 8px #CCC;
    }

    a+a {
        margin-left: 10px;
    }
}
@charset "utf-8";

.layout-post {
    h1 {
        margin-bottom: 10px;
    }

    .date {
        font-size: 0.8em;
        font-weight: 300;
        color: $color-post-path;
        margin-bottom: 20px;
    }

    .content {}


    .title-image.thumbnail {
        float: left;
        margin-right: 25px;
        margin-bottom: 20px;
        border-radius: 0px;
        width: 50%;
        margin-top: 5px;

        @media (max-width: $max-width-collapsed-menu) {
            padding: 0;
            width: 100%; //80%;
            float: none;
            display: block;
            margin: 20px auto;
        }
    }
}
@charset "utf-8";

.site-projects {
    .project {
        //border-left: 1px solid $color-uw-dark-blue;
        //background-color: $color-light-gray;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        $offset: 15px;
        padding: 0 $offset $offset $offset;

        .left {
            flex: 0 0 130px;

            img {
                background-color: white;
                border-radius: 0px;
                margin: 0;
            }
        }

        .right {
            margin: -5px 0 0 $offset;

            .title {}

            .date {
                display: inline-block;
                color: $grey-color;
                font-weight: 300;
            }

            .excerpt {
                font-size: $small-font-size;
                //color: $grey-color;
            }
        }

        &+.project {
            margin-top: 20px;
        }
    }
}
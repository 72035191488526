@charset "utf-8";

.feedback {
    @media (max-width: 993px + 100px) {
        //display: none;
    }

    z-index: 1;
    position: relative;
    //position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .open-button {
        font-size: $small-font-size;
        margin: 150px 0 0 0;
        padding: 10px 20px;
        $border-radius: 5px;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        position: absolute;
        //left: 0px;
        //top: 100px;
        background-color: $cl-grey-100;
        border: $cl-grey-300;
        color: $cl-text-disabled;
        transform: rotate(90deg);
        transform-origin: left bottom 0;

        &:hover {
            background-color: $cl-grey-300;
            color: $cl-text-primary;
            cursor: pointer;
        }

        float: left;

        @media (max-width: 993px + 100px) {
            display: none;
        }
    }

    .feedback-modal {
        background-color: white;
        $border-width: 3px;
        border: $border-width solid $color-uw-dark-blue;
        padding: 20px;
        border-radius: 5px;
        //box-shadow: 0px 3px 53px 23px rgba(255,255,255,1);
        position: absolute;
        width: 60%;
        left: 20%;
        top: -($border-width + 5px);

        @media (max-width: 993px + 100px) {
            width: 80%;
            left: 10%;
        }

        &.hidden {
            display: none;
        }

        .title {
            font-weight: 300;
            margin: 10px 0;
        }

        textarea {
            resize: none;
            width: 100%;
            height: 100px;
        }

        .cancel-button,
        .send-button {
            background-color: rgba(0, 0, 0, 0.2);
            padding: 10px 20px;
            border-radius: 5px;
            font-size: $font-size-in-text-small;
            font-weight: 300;
            display: inline-block;
            margin: 10px 20px 0 0;

            &:hover {
                cursor: pointer;
                background-color: rgba(0, 0, 0, 0.3);
            }
        }
    }
}
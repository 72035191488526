@charset "utf-8";

.my-table-old {
  width: 90%;
  margin: 30px;
  vertical-align: middle;

  .row {
    border-left: 1px solid $color-uw-dark-blue;
    background-color: $color-light-gray;
    padding: 10px;

    div:first-child {
      font-weight: 400;
      color: $color-table-title;
    }
  }

  .row+.row {
    margin-top: 10px;
  }
}

.my-table {
  vertical-align: middle;
  width: 90%;
  margin: 0 5%;

  &.large {
    width: 98%;
    margin: 0 1%;
  }

  th {
    font-family: 'Roboto Slab', serif;
    padding: 0 0 0 10px;
    color: $cl-text-secondary;
    vertical-align: bottom;
  }

  tr+tr {
    border-left: 1px solid $color-uw-dark-blue;
  }

  td {
    background-color: $color-light-gray;
    padding: 10px;
    border-top: 10px solid white;
  }

  .border-col {
    font-weight: 400;
    color: $color-table-title;
    border-left: 1px solid $color-uw-dark-blue;
  }
}

.my-table2 {
  th {
    padding: 10px;
    //background-color: $color-table-header;
  }

  td {
    background-color: $color-light-gray;
    padding: 10px;
    border-top: 10px solid white;

    ul {
      padding-left: 15px;
    }
  }

  .border-col {

    border-left: 1px solid $color-uw-dark-blue;
  }
}
@charset "utf-8";
// Reference: https://material.google.com/style/color.html#color-color-palette
$cl-grey-300: #E0E0E0;
$cl-grey-100: #F5F5F5;
$cl-grey-50: #FAFAFA;
$cl-white: #FFFFFF;

$cl-text-primary: rgba(0, 0, 0, 0.87);
$cl-text-secondary: rgba(0, 0, 0, 0.54);
$cl-text-disabled: rgba(0, 0, 0, 0.38);

$cl-text-primary-darkbg: rgba(255, 255, 255, 1);
$cl-text-secondary-darkbg: rgba(255, 255, 255, 0.7);
$cl-text-disabled-darkbg: rgba(255, 255, 255, 0.5);

$cl-dividers: rgba(0, 0, 0, 0.12);


$cl-orange-dark: rgb(238, 79, 0);
$cl-orange-very-dark: rgb(174, 58, 0);




$text-color: #111;
$brand-color: #2a7ae2;

$grey-color: #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark: darken($grey-color, 25%);

// Source:
// - https://color.adobe.com/create/color-wheel/?base=2&rule=Compound&selected=3&name=My%20Color%20Theme&mode=rgb&rgbvalues=0.21109939403404943,0.10693232131562308,0.9294117647058824,0.21866591048907055,0.20260594560404804,0.3294117647058823,0.1568627450980392,0.38823529411764707,0.7294117647058823,0.763235294117647,0.5810701288750842,0.3549454459203036,0.9294117647058824,0.4425042471868396,0.10693232131562308&swatchOrder=0,1,2,3,4

$color-uw-bright-blue: rgb(54, 27, 237);
$color-uw-light-blue: rgb(40, 99, 186);
$color-uw-dark-blue: rgb(56, 52, 84);
$color-uw-light-orange: rgb(195, 148, 91);
$color-uw-orange: rgb(237, 113, 27);
$color-light-gray: rgb(245, 245, 245);
$color-hci-orange: rgb(250, 126, 18);
$color-gray: rgb(170, 170, 170);

$color-header-small: $color-uw-orange;
$color-header-large: $color-uw-dark-blue;

$color-post-h1: $color-uw-orange;
//$color-post-h2: $color-uw-light-blue;
$color-post-h2: grey;
$color-post-h3: grey;

$color-table-background: rgb(245, 245, 245);
$color-table-title: $color-uw-light-blue;

$color-background: whitesmoke;
$color-body-backgroud: white;
$color-body-border: rgb(220, 220, 220);

$color-post-path: $color-gray;

$color-post-list-backgroud: $color-light-gray;

// header
$color-header-title-border: $color-hci-orange;
//$color-header-bar: $color-hci-orange;
$color-header-bar: $color-uw-orange;

// vertival-nav
$nav-category-font-color-inverted: #fff;
$nav-subcategory-font-color: #afdefa;
$color-nav-line-backgroud: $color-light-gray;
$nav-category-font-color: $color-uw-dark-blue;
//$nav-backgroud-color: $color-hci-orange;
$nav-backgroud-color: $color-uw-dark-blue;
//$color-vertical-nav-border: $color-uw-dark-blue;
$color-vertical-nav-border: white;
//$color-vertical-nav-border: white;
//$nav-category-font-color-inverted: $color-uw-dark-blue;
//$nav-subcategory-font-color: $color-uw-dark-blue;
$color-vertical-nav-background: rgb(220, 220, 220);
$color-vertical-nav-external-link-background: rgba(0, 0, 0, 0.4);

// bibsonomy
$color-publication-title: rgb(90, 90, 90);

$link-color: rgb(238, 79, 0);
$link-color-hover: rgb(174, 58, 0); // rgb(238, 26, 26);
@charset "utf-8";

.follow-us {
    @media print {
        display: none;
    }

    width: 100%;
    display: flex;
    justify-content: center;
    //justify-content: space-around;
    align-items: baseline;
    padding-bottom: 10px;

    div {
        display: inline-block;
        padding: 0 0.5em;
        color: $cl-text-secondary; //$color-gray;
        font-size: $font-size-in-text-small;
        font-weight: 300;
    }

    a {
        img {
            $height: 20px;

            &.youtube {
                height: $height;
            }

            &.feed {
                height: $height;
            }
        }
    }

    a+div {
        padding-left: 20px;
    }
}
@charset "utf-8";

.site-supervision-overview {
    font-size: $font-size-in-text-small;

    .tablesorter-headerDesc,
    .tablesorter-headerAsc,
    .tablesorter-headerUnSorted {
        &:hover {
            cursor: pointer;
            background-color: lightgray;
        }
    }

    .tablesorter-headerDesc {
        border-bottom: 3px solid black;

        .up,
        .updown {
            display: none;
        }
    }

    .tablesorter-headerAsc {
        border-bottom: 3px solid black;

        .down,
        .updown {
            display: none;
        }
    }

    .tablesorter-headerUnSorted {

        .up,
        .down {
            display: none;
        }

        .updown {
            opacity: 0.5;
        }
    }

    table {

        margin-top: 40px;

        width: 100%;

        td,
        th {
            padding: 5px;

            &+td,
            &+th {
                padding-left: 20px;
            }

            //min-width: 80px;
        }

        th {
            border-bottom: 1px solid $grey-color;

            // For tablesorter classes: https://mottie.github.io/tablesorter/docs/
            //&.tablesorter-headerAsc, &.tablesorter-headerDesc {
            //    background-color: rgba(0, 0, 0, 0.3);
            //}
            //
            //&.tablesorter-headerUnSorted {
            //
            //}
        }

        td {
            border-bottom: 1px solid $grey-color-light;
        }

        .inProgressClass {
            background-color: rgba(0, 0, 0, 0.05);
        }
    }

    .type {
        //display: inline-block;
        color: $grey-color;
        font-weight: 300;
        font-size: $font-size-in-text-small;
        margin: 0 0 10px 0;
        text-align: left;

        >div {
            margin-top: 5px;
            margin-right: 3px;
            display: inline-block;
            border: 1px solid rgb(200, 200, 200);
            border-radius: 3px;
            padding: 0 6px;
            background-color: rgba(255, 255, 255, 0.7);

            &.status {
                background-color: rgba(255, 255, 255, 0.8);
                color: $color-uw-dark-blue;
            }
        }

        span+span {
            margin-left: 7px;
            //padding-left: 7px;
            //border-left: 1px solid $grey-color;
        }
    }

    .search {
        border-left: 1px solid $color-uw-dark-blue;
        background-color: $color-light-gray;
        margin: 20px auto;
        display: block;
        padding: 10px 20px;

        input {
            margin-left: 3px;
        }

        .bibInfo {
            margin-left: 10px;
            color: grey;
            font-weight: 300;
        }
    }
}
@charset "utf-8";

.header {
    .uwb-header {
        margin: auto;
        position: relative;
        height: 77px;
        max-width: 993px;
        overflow: hidden;

        @media (max-width: $max-width-collapsed-background) {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
        }

        .uwb-header-left {
            left: 0px;
            position: absolute;
        }

        .uwb-header-right {
            left: 164px;
            position: absolute;
            min-width: 829px;
            overflow: hidden;
        }

        .hci-logo {
            position: absolute;
            z-index: 1000;
            $height: 55px;
            height: $height;
            top: (77px - $height) / 2;
            //right: 120px;
            right: 19px;

            @media (max-width: $max-width-collapsed-header-logos) {
                display: none;
            }
        }

        .title {
            position: absolute;
            top: 0;
            left: 230px;
            color: white;
            font-weight: 300;
            padding: 0.5em 15px;
            border-left: 2px solid $color-header-title-border;
            font-family: 'Roboto Slab', serif;

            @media (max-width: $max-width-collapsed-header-title) {
                display: none;
            }
        }
    }

    .only-show-on-printer {
        display: none;

        @media print {
            margin-top: 10px;
            font-size: 12px;
            width: 100%;
            text-align: center;
            display: block;
            color: grey;
            font-weight: 300;
            $border: 1px solid rgb(100, 100, 100);
            border-top: $border;
            border-bottom: $border;
            padding: 1px 0;
            //border-left: $border;
            //border-right: $border;
        }
    }

    .orange-bar {
        margin-top: 0px;
        background-color: $color-hci-orange;
        position: relative;
        width: 100%;
        height: 28px;

        @media print {
            display: none;
        }

        @media (max-width: $max-width-collapsed-background) {
            border-bottom: 1px solid $cl-orange-dark; //$color-hci-orange;
            background-color: inherit;
            height: auto;
        }

        .navigation {
            @media (min-width: $max-width-collapsed-background + 1px) {
                display: none;
            }

            @media (max-width: $max-width-collapsed-menu) {
                display: none;
            }

            @media print {
                display: none;
            }

            padding: 10px 0px 10px $menu-offset-left-large;

            .external {
                display: inline-block;

                .arrow {
                    display: none;
                }

                .text {
                    margin-right: 10px;
                    color: $cl-text-disabled;
                }
            }
        }
    }
}
@charset "utf-8";

.highlight-block {
    border-left: 1px solid $color-uw-dark-blue;
    background-color: $cl-grey-100; //$color-light-gray;
    padding: 10px;

    .key {
        color: $cl-text-secondary; //$grey-color;
    }

    .value {
        margin-left: 10px;
    }

    &+.highlight-block {
        margin-top: 20px;
    }

    .tag {
        color: $cl-text-secondary; //$grey-color;
        font-weight: 300;
        font-size: $font-size-in-text-small;
        margin: 0px 0 0px 10px;

        border: 1px solid $cl-dividers; //rgb(200, 200, 200);
        border-radius: 3px;
        padding: 0 6px;
        background-color: $cl-grey-100; //rgba(255, 255, 255, 0.7);
        display: inline-block;
        //margin-top: 5px;
    }
}
@charset "utf-8";

.my.note {
    border-left: 1px solid $color-uw-dark-blue;
    background-color: $color-light-gray;
    margin: 20px auto;
    display: block;
    padding: 10px 20px;
    width: 95%;

    &.large {
        width: 98%;
        margin: 20px 1%;
    }
}
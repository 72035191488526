@charset "utf-8";
$base-font-family: 'Open Sans',
'Arial',
sans-serif;
$base-font-size: 16px;
$base-font-weight: 400;
$small-font-size: $base-font-size * 0.875;
$base-line-height: 1.5;
$spacing-unit: 30px;



// Width of the content area
$content-width: 800px;

$on-palm: 600px;
$on-laptop: 800px;


$content-border-radius: 0px;
$width-partners-flag: 120px;

$vertical-nav-padding: 17px;
$vertical-nav-width: 210px;

$menu-offset-left-large: 52px;
$menu-offset-left-small: 18px;
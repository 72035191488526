@charset "utf-8";

table {
    td {
        &:first-child {
            width: 125px;
        }
    }

    td,
    th {
        padding-bottom: 10px;
        vertical-align: top;
    }
}
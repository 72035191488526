@charset "utf-8";

.nav-horizontal {
    @media (min-width: $max-width-collapsed-background + 1) {
        display: none;
    }

    @media print {
        display: none;
    }

    .nav-horizontal-content {
        width: 100%;
        background-color: $color-nav-line-backgroud;
        padding-left: $menu-offset-left-large - $menu-offset-left-small;

        @media screen and (max-width: $max-width-collapsed-menu) {
            background-color: inherit;
            padding-left: 0;
        }

        a {
            outline: none;
            //color: $brand-color;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }

            .menu-item-prefix {
                font-size: 10px;
            }
        }

        .category {
            display: inline-block;

            @media screen and (max-width: $max-width-collapsed-menu) {
                display: block;
                //border-bottom: 1px solid $nav-backgroud-color;
                border-top: 1px solid white;
                //border-left: 1px solid $nav-backgroud-color;
                margin: 0 $menu-offset-left-small;
                background-color: $color-nav-line-backgroud;
            }

            >a {
                font-weight: 400;
                padding: 10px $menu-offset-left-small;
                color: $nav-category-font-color;
                display: inline-block;

                @media screen and (max-width: $max-width-collapsed-menu) {
                    display: block;
                }
            }

            .menu-item {
                display: flex;
                align-items: center;

                .menu-item-prefix {
                    color: rgba(255, 255, 255, 0.5);
                    font-size: 11px;
                    margin-right: 7px;
                }
            }


            .submenu-wrapper {
                display: none; //makes invsisible unless js makes visible
                width: 100%;

                position: absolute;
                left: 0;
                z-index: 9999;

                @media screen and (max-width: $max-width-collapsed-menu) {
                    position: relative;
                }

                .submenu {
                    flex: 1;
                    background: $nav-backgroud-color;
                    padding-bottom: 10px;
                    padding-left: $menu-offset-left-large - $menu-offset-left-small;

                    @media screen and (max-width: $max-width-collapsed-menu) {
                        padding-left: $menu-offset-left-small;
                    }

                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                    .category-header {
                        padding-top: 10px;
                        flex-basis: 100%;


                        @media screen and (max-width: $max-width-collapsed-menu) {
                            padding-top: 0px;
                        }

                        >a {
                            font-weight: 300;
                            color: white;
                            font-family: 'Roboto Slab', serif;
                            font-size: 18px;
                        }
                    }

                    >div {
                        flex-basis: 50%;
                        padding-top: 15px;

                        @media screen and (max-width: $max-width-collapsed-submenu) {
                            flex-basis: 100%;
                        }

                        .subcategory-title {
                            padding-top: 10px;
                            font-family: 'Roboto Slab', serif;
                            font-size: 16px;
                            font-weight: 300;
                        }
                    }

                }

                .placeholder-left,
                .placeholder-right {
                    flex: 0 0 $menu-offset-left-small;

                    @media screen and (max-width: $max-width-collapsed-menu) {
                        display: none;
                    }
                }
            }
        }

        .category.is-open a,
        .category>a:hover {
            background: $nav-backgroud-color;
            color: $grey-color-light;
        }

        .category.is-open>a {
            color: $cl-text-secondary-darkbg;

            &:hover {
                text-decoration: none;
                cursor: default;
            }
        }

        .is-open .submenu-wrapper {
            display: flex;
        }

        .submenu>div a {
            line-height: 2em;
        }
    }

    .path {
        color: $cl-text-disabled;

        @media screen and (max-width: $max-width-collapsed-background) {
            padding: 10px 0 0 $menu-offset-left-large;
        }

        @media (max-width: $max-width-collapsed-menu) {
            padding: 10px 0 0 2 * $menu-offset-left-small;
        }
    }
}
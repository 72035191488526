@charset "utf-8";

.post {
    //$large-width: 722px;
    $large-width: 730px;
    //$horizontal-offset-between-posts: 40px;
    $horizontal-offset-between-posts: -1px;
    $vertical-offset-between-posts: 20px;
    //$column-width: (725px - $vertical-offset-between-posts) / 2 - 10px;
    $column-width: ($large-width - $vertical-offset-between-posts) / 2;
    $column-height: ($column-width / 16 * 9);
    margin-bottom: 20px;


    //margin-bottom: $horizontal-offset-between-posts;

    display: block;

    &.hidden {
        display: none;
    }

    //display: block;


    &:hover {
        //text-decoration: none;
        //cursor: pointer;
    }

    .image {
        position: relative;

        img {
            margin: 0;
            width: 100%;
        }

        .shadow {
            position: absolute;
            width: 100%;
            height: 100%;
            box-shadow: inset 0px 0px 65px -9px rgba(0, 0, 0, 0.42);
            top: 0;
            left: 0;
        }
    }

    .title {
        margin-top: 10px;
        font-family: 'Roboto Slab', serif;

        .event-date {
            font-size: $font-size-in-text-small;
            color: $cl-text-secondary; //$cl-text-disabled;//rgba(0, 0, 0, 0.4);
            font-weight: 300;
        }
    }

    .excerpt {
        //color: rgba(0, 0, 0, 0.6);
        text-align: justify;
        padding: 3px 0 0px 0;

        .date {
            font-family: 'Roboto Slab', serif;
            display: inline-block;
            //border: 1px solid blue;
            //text-align: right;
            //width: 100%;
            //margin-top: -16px;
            float: right;
            //padding: 5px;
            margin-top: -1px;
            padding: 0px 0 0 10px;
            //font-size: 0.8em;
            font-size: $font-size-in-text-small;
            font-weight: 300;
            color: $cl-text-secondary; //$cl-text-disabled;//$color-post-path;
        }
    }

    &.large {
        @media all and (min-width: $max-width-collapsed-background + 1px) {
            width: 100%;

            .image {
                img {
                    //width: $width;
                    width: $large-width;
                    height: $large-width / 16 * 9;
                }
            }

            .title {
                margin-top: 10px;
            }

            .excerpt {
                .date {
                    display: inline-block;
                    float: right;
                    padding: 3px 0 0 10px;
                }
            }
        }
    }

    &.panorama {
        @media all and (min-width: $max-width-collapsed-background + 1px) {
            width: 100%;

            .image {
                img {
                    //width: $width;
                    width: $large-width;
                    height: $column-height - 3;
                }
            }

            .title {
                margin-top: 10px;
            }

            .excerpt {
                .date {
                    display: inline-block;
                    float: right;
                    padding: 3px 0 0 10px;
                }
            }
        }
    }

    &.large,
    &.panorama {
        .placeholder {
            width: 100%;
            //height: $horizontal-offset-between-posts - 17px;
            height: $horizontal-offset-between-posts + 17px;
        }
    }

    &.flat {
        @media all and (min-width: $max-width-collapsed-background + 1px) {
            margin-bottom: $horizontal-offset-between-posts + 23px;

            .image {
                display: inline-block;
                width: $column-width;
                margin-right: $vertical-offset-between-posts;
                float: left;

                img {
                    width: $column-width;
                    height: $column-height;
                }

                .thumbnail-wrapper {
                    width: $column-width;
                    height: $column-height;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $grey-color-light;

                    img {
                        width: $column-width / 2;
                        height: $column-width / 2;
                    }
                }
            }

            .title {
                margin-top: -4px;
            }

            .excerpt {
                .date {
                    display: inline-block;
                    float: right;
                    padding: 3px 0 0 10px;
                }
            }
        }
    }

    &.portrait {
        @media all and (min-width: 400px + 1px) {
            margin-bottom: $horizontal-offset-between-posts + 23px;

            .image {
                display: inline-block;
                width: 150px;
                margin-right: $vertical-offset-between-posts;
                float: left;

                img {
                    width: 150px;
                    height: $column-height;
                }

                .thumbnail-wrapper {
                    width: $column-width;
                    height: $column-height;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $grey-color-light;

                    img {
                        width: $column-width / 2;
                        height: $column-width / 2;
                    }
                }
            }

            .title {
                margin-top: -4px;
            }

            .excerpt {
                .date {
                    display: inline-block;
                    float: right;
                    padding: 3px 0 0 10px;
                }
            }
        }
    }

    &.narrow {
        @media all and (min-width: $max-width-collapsed-background + 1px) {
            width: $column-width;
            display: inline-block;
            vertical-align: top;

            &.left {
                margin-right: $vertical-offset-between-posts;
            }

            //margin: 0;
            float: left;

            .image {
                //display: inline-block;
                //margin-right: $vertical-offset-between-posts;
                //float: left;
                width: 100%;

                img {
                    width: 100%;
                    //width: $column-width;
                    height: $column-width / 16 * 9;
                }
            }

            .title {
                margin-top: 10px;
            }

            .excerpt {
                .date {
                    display: inline-block;
                    //float: right;
                    padding: 3px 0 0 10px;
                }
            }
        }
    }

    &.large,
    &.flat,
    &.narrow {
        @media all and (max-width: $max-width-collapsed-background) and (min-width: $max-width-collapsed-menu + 1px) {
            margin-bottom: 23px;

            .image {
                display: inline-block;
                width: $column-width;
                margin-right: $vertical-offset-between-posts + 5px;
                float: left;

                img {
                    width: $column-width;
                    height: $column-width / 16 * 9;
                }

                .thumbnail-wrapper {
                    width: $column-width;
                    height: $column-width / 16 * 9;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $grey-color-light;

                    img {
                        width: $column-width / 2;
                        height: $column-width / 2;
                    }
                }
            }

            .title {
                margin-top: -4px;
                padding-right: 23px;
            }

            .excerpt {
                padding-right: 23px;

                .date {
                    display: inline-block;
                    float: right;
                    padding: 3px 0 0 10px;
                }
            }
        }
    }
}
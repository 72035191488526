@charset "utf-8";

.site-publications {
    .search {
        border-left: 1px solid $color-uw-dark-blue;
        background-color: $color-light-gray;
        margin: 20px auto;
        display: block;
        padding: 10px 20px;

        input {
            margin-left: 3px;
        }

        .bibInfo {
            margin-left: 10px;
            color: grey;
            font-weight: 300;
        }
    }
}
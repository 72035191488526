@charset "utf-8";

.site-teaching-topics {
    .options {
        margin-bottom: 50px;

        .status {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            >div {
                flex-grow: 1;
                border-bottom: 1px solid $color-gray;
                padding: 4px 10px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                font-weight: 300;

                &.active {
                    border-bottom: 1px solid $color-uw-dark-blue;
                    background-color: $color-light-gray;
                }
            }
        }

        .type {
            margin-top: 10px;

            .type-row {
                border-left: 1px solid $color-uw-dark-blue;
                width: 100%;
                background-color: $color-light-gray;
                padding: 7px 20px 0 20px;

                .title {
                    margin-bottom: 0px;
                    font-weight: 300;
                }

                .block {
                    display: inline-block;
                    margin: 0 40px 0 0;
                    //border: 1px solid $color-gray;
                    //border-radius: 5px;

                    .text {
                        display: inline-block;
                        //min-width: 70px;
                        font-weight: 300;
                        margin-right: 7px;
                    }

                    .checkbox {
                        min-width: 90px;

                        display: inline-block;
                        border: 1px solid rgb(200, 200, 200);
                        border-radius: 3px;
                        padding: 0 5px;
                        background-color: rgba(255, 255, 255, 0.7);

                        label {
                            color: $grey-color;
                            font-weight: 300;
                            font-size: $font-size-in-text-small;

                            input {
                                padding: 0 5px;
                            }
                        }

                        margin-right: 10px;

                        input {
                            position: relative;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }

        .ruler {
            width: 100%;
            margin-top: 10px;
            border-bottom: 1px solid $color-gray;
        }
    }

    .topic-row {
        //display: flex;
        //flex-direction: row;
        //justify-content: flex-start;
        //padding: 10px 0;

        &.hidden-by-type,
        &.hidden-by-status {
            display: none;
        }

        //.left {
        //    flex: 0 0 355px;
        //    background-color: whitesmoke;
        //    img {
        //        background-color: white;
        //        //border-radius: 0px;
        //        margin: 0;
        //        width: 100%;
        //    }
        //}
        //
        //.right {
        //    margin: -5px 10px 0 25px;
        //    .title {
        //
        //    }
        //
        //    img {
        //        //border-radius: 5px;
        //        margin: 5px 0 10px 0;
        //        width: 100%;
        //    }
        //
        .type {
            //display: inline-block;
            color: $grey-color;
            font-weight: 300;
            font-size: $font-size-in-text-small;
            margin: 0 0 10px 0;
            text-align: left;

            >div {
                margin-top: 5px;
                margin-right: 3px;
                display: inline-block;
                border: 1px solid rgb(200, 200, 200);
                border-radius: 3px;
                padding: 0 6px;
                background-color: rgba(255, 255, 255, 0.7);

                &.status {
                    background-color: rgba(255, 255, 255, 0.8);
                    color: $color-uw-dark-blue;
                }
            }

            span+span {
                margin-left: 7px;
                //padding-left: 7px;
                //border-left: 1px solid $grey-color;
            }
        }

        //
        //    .date {
        //        display: inline-block;
        //        color: $grey-color;
        //        font-weight: 300;
        //    }
        //
        //    .excerpt {
        //
        //    }
        //}

        &+.topic-row {
            margin-top: 35px;
        }
    }
}
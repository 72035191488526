@charset "utf-8";

.carousel {
    width: 100%;
    margin: 0;

    //display: none;
    @media all and (max-width: $max-width-collapsed-menu) {
        //display: none;
    }

    //width: 720px;
    //margin-bottom: 0px;

    .carousel-indicators {}

    .carousel-inner {
        //border-radius: 5px;
        overflow: hidden;
        position: relative;

        .item {

            img {
                //height: 320px;
                //margin: 0px;
                //border-radius: 0px;
            }
        }
    }

    .carousel-control {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        opacity: 1;

        &.right {
            background-image: linear-gradient(to right, rgba(0, 0, 0, .0001) 0, rgba(0, 0, 0, .2) 100%);
        }

        &.left {
            background-image: linear-gradient(to left, rgba(0, 0, 0, .0001) 0, rgba(0, 0, 0, .2) 100%);
        }

        span {
            margin-bottom: 32px;
            text-shadow: none;
        }
    }

    a {
        //border-radius: 5px;
    }
}

.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }

        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
    }
}
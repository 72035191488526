@charset "utf-8";

.site-teaching-lectures {
    font-size: $font-size-in-text-small;

    h3 {
        margin-top: 40px;
    }

    table {

        width: 100%;

        td,
        th {
            padding: 5px 0;

            &+td,
            &+th {
                padding-left: 30px;
            }
        }

        th {
            border-bottom: 1px solid $grey-color;
        }

        td {
            border-bottom: 1px solid $grey-color-light;
        }
    }

    .show-all-button {
        //padding-top: 40px;
        //padding-bottom: 20px;
        //
        //@media (min-width: $max-width-collapsed-background + 1) {
        //    padding-left: 205px;
        //}
        //
        //@media screen and (max-width: $max-width-collapsed-background) {
        //    display: flex;
        //    justify-content: center;
        //}
        color: $cl-text-secondary;
        font-size: $font-size-in-text-small;
        font-weight: 300;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .hidden {
        visibility: hidden;
    }
}
@charset "utf-8";

.layout-topic {
    .type {
        //display: inline-block;
        color: $grey-color;
        font-weight: 300;
        font-size: $font-size-in-text-small;
        margin: -15px 0 30px 0;

        span {
            border: 1px solid rgb(200, 200, 200);
            border-radius: 3px;
            padding: 0 6px;
            background-color: rgba(255, 255, 255, 0.7);
            display: inline-block;
            margin-top: 5px;

            &.status {
                background-color: rgba(255, 255, 255, 0.8);
                //color: $color-uw-dark-blue;
            }
        }

        >div {
            margin-bottom: 5px;
        }
    }
}
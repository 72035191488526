@charset "utf-8";
$base-font-size: 13.5px;
$font-size-normal: 12.5px; //16px;
// $font-size-normal: 16px;
$font-size-in-text-small: 12px;
$font-size-in-text-extra-small: 10px;
$font-size-content-text: 15px;
//$font-size-h1: 30px;
$font-size-h1: 40px;
//$font-size-h2: 20px;
$font-size-h2: 25px;
$font-size-h3: 18px;
$font-size-h4: 17px;
$font-size-index-news-header: 18px;

$font-size-vertical-nav: 12.5px;
@charset "utf-8";

.content {
    @media (min-width: $max-width-collapsed-background) {
        //padding-right: 60px;
    }

    margin-bottom: $spacing-unit;
    //font-size: $font-size-content-text;
    //font-size: $font-size-in-text-small;

    img {
        //border-radius: 5px;
        margin-top: 10px;
        margin-bottom: 20px;
        max-width: 100%;
        width: 100%;

        // Caption
        &+em {
            display: block;
            font-size: 80%;
            margin-top: -13px;
            margin-bottom: 26px;
        }
    }

    // YouTube player
    iframe {
        width: 80%;
        margin-left: 10%;

        @media screen and (max-width: $max-width-collapsed-submenu) {
            width: 100%;
            margin-left: 00%;
        }
    }

    h1 {
        margin-bottom: 30px;
    }

    strong {
        color: $grey-color-dark;
    }

    .gray-light {
        color: $color-gray;
        font-weight: 300;
    }

    .no-padding {
        padding: 0;
    }
}
@charset "utf-8";

.site-index {
    //display: flex;
    //justify-content: space-between;


    //margin: 0;
    //padding: 0 20px 0 0;
    //width: 100%;
    //
    ////$width-post-list: 80%;
    //$width-post-list: 100%;
    //
    //
    //display: block;
    ////display: inline-block;
    //width: $width-post-list;
    //margin: 0;
    ////margin-right: 30px;
    //padding: 0;
    //list-style: none;

    @media screen and (max-width: $max-width-collapsed-menu) {
        width: 100%;
    }

    h1 {
        margin-bottom: 42px; //27px;
    }


    &+li {
        //margin-top: 40px;
    }


    //display: flex;
    //flex-direction: row;
    //justify-content: flex-start;
    //width: 100%;
    //
    //@media all and (max-width: $max-width-collapsed-news) {
    //    flex-direction: column;
    //}
    //
    //.date {
    //    flex: 0 0 70px;
    //    color: $color-gray;
    //    font-size: $font-size-in-text-small;
    //    font-weight: 300;
    //    letter-spacing: 0px;
    //    text-align: right;
    //    padding-right: 10px;
    //    padding-left: 0px;
    //    margin-top: 8px;
    //
    //    @media all and (max-width: $max-width-collapsed-news) {
    //        text-align: left;
    //        margin: 0 0 5px 0;
    //        padding: 0;
    //        flex: 0 0 0;
    //    }
    //}
    //
    //.content {
    //    border-left: 2px solid $color-uw-dark-blue;
    //
    //    @media all and (max-width: $max-width-collapsed-news) {
    //        flex-direction: column;
    //    }
    //
    //    &:hover {
    //        text-decoration: none;
    //        cursor: pointer;
    //        background-color: $color-light-gray;
    //    }
    //
    //    &.big-image {
    //        padding: 3px 10px 10px 10px;
    //
    //        img {
    //            border-radius: 5px;
    //            margin-bottom: 10px;
    //            margin-top: 5px;
    //            width: 100%;
    //        }
    //
    //        .excerpt {
    //            font-size: $small-font-size;
    //            color: $grey-color;
    //        }
    //
    //        @media (max-width: $max-width-collapsed-menu) {
    //            width: 100%;
    //        }
    //    }
    //
    //    &.small-image {
    //        display: flex;
    //        flex-direction: row;
    //        justify-content: flex-start;
    //
    //        .image {
    //            flex: 0 0 130px;
    //            //border-radius: 5px;
    //            //margin-bottom: 10px;
    //            //margin-top: 5px;
    //            //width: 100%;
    //            margin: 0px 15px;
    //            img {
    //                width: 100%;
    //                border: 1px solid $color-light-gray;
    //                border-radius: 5px;
    //                background-color: white;
    //            }
    //        }
    //
    //        .text {
    //            margin: 5px 0;
    //            @media all and (max-width: $max-width-collapsed-news) {
    //                margin: 3px 15px 10px 15px;
    //            }
    //
    //            .title {
    //                font-size: $font-size-index-news-header;
    //            }
    //
    //            .excerpt {
    //                font-size: $small-font-size;
    //                color: $grey-color;
    //            }
    //        }
    //    }
    //}
}

.show-all-button {
    cursor: pointer;
    padding-top: 40px;
    padding-bottom: 20px;

    @media (min-width: $max-width-collapsed-background + 1) {
        padding-left: 205px;
    }

    @media screen and (max-width: $max-width-collapsed-background) {
        display: flex;
        justify-content: center;
    }

    color: $cl-text-secondary; //$color-gray;
    font-size: $font-size-in-text-small;
    font-weight: 300;
}
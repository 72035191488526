@charset "utf-8";

h1,
h2,
h3,
h4 {
    font-family: 'Roboto Slab', serif;
}

h1 {
    font-size: 33px;
    color: $cl-orange-dark; //$cl-text-secondary;//$color-post-h1;
    margin-top: -7px;
    margin-bottom: 10px;
    line-height: 120%;

    //font-size: $font-size-h1;
    //font-weight: 300;
    //color: $cl-orange-dark;//$cl-text-secondary;//$color-post-h1;
    //margin-top: -10px;
    //margin-bottom: 10px;
    //line-height: 120%;

    @media (max-width: $max-width-increased-header-font-sizes) {
        font-size: 36px;
    }
}

h2,
h3,
h4 {
    color: $color-uw-dark-blue;
}

h2 {
    font-size: $font-size-h2;
    //color: $cl-text-secondary;//$color-post-h2;
    //font-weight: 300;
    color: $color-uw-dark-blue;

    @media (max-width: $max-width-increased-header-font-sizes) {
        font-size: 28px;
    }
}

h3 {
    font-size: $font-size-h3;
    //color: $cl-text-secondary;//$color-post-h3;
    //font-weight: 300;

    @media (max-width: $max-width-increased-header-font-sizes) {
        font-size: 22px;
    }
}

h4 {
    font-size: $font-size-h4;

    @media (max-width: $max-width-increased-header-font-sizes) {
        font-size: 18px;
    }
}

a {
    color: $cl-orange-dark;
    //color: $color-hci-orange;

    &:hover {
        color: $cl-orange-very-dark;
    }
}

i {
    &.external {
        &:after {
            content: "↗";
            display: inline-block;
            font-size: 80%;
            margin: 0;
        }
    }
}
@charset "utf-8";

.layout-person {
    .image-and-text {
        overflow: hidden;

        .mugshot {
            float: left;
            margin-right: 25px;
            margin-bottom: 20px;
            border-radius: 0px;
            height: 200px;
            width: 150px;
            margin-top: 5px;

            @media (max-width: $max-width-collapsed-menu) {
                padding: 0;
                //width: 50%;
                float: none;
                display: block;
                margin: 20px auto;
            }
        }
    }
}
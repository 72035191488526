@charset "utf-8";

.footer {
    @media print {
        display: none;
    }

    img {
        vertical-align: middle;
    }

    margin: 0 auto;
    text-align: center;
    padding: 10px;

    a {
        font-weight: 300;
        color: $cl-text-secondary;
        font-size: $font-size-in-text-small;
    }

    a+a {
        margin-left: 40px;
    }

    .feedback-footer-button {
        @media (min-width: 993px + 100px) {
            display: none;
        }

        //font-size: $small-font-size;
        font-weight: 300;
        color: $cl-text-secondary;
        font-size: $font-size-in-text-small;

        $border-radius: 5px;
        border-radius: 5px;
        background-color: $cl-grey-100; //rgba(0, 0, 0, 0.06);
        border: $cl-dividers; //rgba(0, 0, 0, 0.2);
        color: $cl-text-secondary; //rgba(0, 0, 0, 0.25);
        width: 150px;
        margin: 10px auto;
        padding: 5px 10px;

        &:hover {
            background-color: $cl-grey-300; //rgba(0, 0, 0, 0.2);
            color: $cl-text-primary; //rgba(0, 0, 0, 0.6);
            cursor: pointer;
        }
    }
}
@charset "utf-8";

.faq {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
        margin-top: 20px;

        .question {
            font-weight: 400;
            color: $color-uw-dark-blue;
            border-left: 2px solid $color-uw-dark-blue;
            padding: 7px 14px;
            background-color: whitesmoke;
        }

        .answer {
            padding: 7px 14px 0px 16px;
            font-weight: 400;
            color: $grey-color-dark;
            font-size: $font-size-in-text-small;
            margin: 0;

            >p {
                margin: 0;
            }

            ul {
                padding: 0 30px;
                padding: 0 0 10px 30px;
                list-style-type: disc;

                li {
                    margin: 4px 0 0 0;
                }
            }
        }

        .date {
            display: inline-block;
            margin: 0;
            padding: 0px 0px 7px 16px;
            font-size: $font-size-in-text-small;
            color: $color-post-path;
            font-weight: 300;

            >span {
                margin: 0;
                padding: 0;
            }
        }

        .tag-list {
            display: inline-block;
            padding: 0px 0px 7px 16px;
            margin: 0;

            .tag {
                color: rgba(0, 0, 0, 0.6);
                //background-color: rgba(0, 0, 0, 0.3);
                border: 1px solid rgba(0, 0, 0, 0.3);
                font-size: $font-size-in-text-small;
                font-weight: 300;
                padding: 1px 5px;
                display: inline-block;
                border-radius: 6px;
                //color: white;
            }

            .tag+.tag {
                margin-left: 3px;
            }
        }
    }
}

.right {
    margin-top: 20px;
    color: $grey-color-dark;
    font-size: $font-size-in-text-small;
}
@charset "utf-8";

body {
    margin: 0;
    z-index: 0;
    position: relative;
    font: $base-font-weight $base-font-size $base-font-family;
    background-color: $cl-grey-50;
    color: $cl-text-primary;

    .page-wrapper {
        z-index: 0;
        position: relative;

        margin-top: 0px;
        margin-left: auto;
        margin-right: auto;
        vertical-align: top;
        background-color: $color-body-backgroud;
        //max-width: $max-width-collapsed-background;
        max-width: 993px;
        $border: 1px solid $cl-dividers;
        border-left: $border;
        border-right: $border;
        border-bottom: $border;

        @media print {
            border: none;
        }


        @media screen and (max-width: $max-width-collapsed-background) {
            border-radius: 0px;
            margin-top: 0;
        }

        .side-bar {
            @media (max-width: $max-width-collapsed-background) {
                display: none;
            }

            position: relative;
            z-index: 0;
            display: inline-block;
            vertical-align: top;
            min-height: 430px;
            margin: 0;
        }

        .content-wrapper {
            @media screen and (min-width: $max-width-collapsed-background + 1) {
                // Case: Wide screen
                display: inline-block;
                //max-width: 70%;
                //padding-right: 50px;
                padding-top: 17px;
                padding-left: 0px;
                padding-right: 17px;
                width: 993px - ($vertical-nav-width + 2 * $vertical-nav-padding) - 2px;
            }

            @media screen and (max-width: $max-width-collapsed-background) {
                //width: 80%;
                //margin-left: auto;
                //margin-right: auto;
                //margin-top: 30px;
                margin: 30px $menu-offset-left-large 0 52px;
            }

            @media (max-width: $max-width-collapsed-menu) {
                //width: 90%;
                //margin-left: auto;
                //margin-right: auto;
                //margin-top: 30px;
                margin: 30px 2 * $menu-offset-left-small 0 36px;
            }
        }
    }
}
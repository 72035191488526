@charset "utf-8";
$max-width-collapsed-menu: 815px;
$max-width-collapsed-submenu: 36em;
//$max-width-collapsed-background: 993px;
$max-width-collapsed-background: 1007px;
$max-width-collapsed-index-columns: 30em;
$max-width-collapsed-header-title: 620px;
$max-width-collapsed-header-logos: 260px;
$max-width-collapsed-orange-vertical-menu: 435px;
$max-width-increased-header-font-sizes: 800px;
$max-width-collapsed-news: 650px;
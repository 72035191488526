@charset "utf-8";

.header-line {
    width: 100%;
    //border-bottom: 1px solid $color-uw-dark-blue;

    @media (max-width: $max-width-collapsed-background) {
        display: none;
    }

    @media print {
        display: none;
    }
}

.vertical-nav {
    //$color-hierarchy-1: rgba(255, 255, 255, 0.8);
    //$color-hierarchy-2: $nav-subcategory-font-color;
    //$color-hierarchy-3: rgba(255, 255, 255, 0.8);
    //$color-hierarchy-1: rgba(237, 209, 255, 1.0);
    //$color-hierarchy-2: rgba(237, 209, 255, 0.9);
    //$color-hierarchy-3: rgba(237, 209, 255, 0.8);
    $color-hierarchy-1: rgba(255, 255, 255, 0.8);
    $color-hierarchy-2: rgba(255, 255, 255, 0.8);
    $color-hierarchy-3: rgba(255, 255, 255, 0.8);
    $font-weight: 400;

    @media (min-width: $max-width-collapsed-background) {
        //display: inline-block;
        //width: 29%;
        //vertical-align: top;
        position: relative;
        z-index: 1;
    }

    @media (max-width: $max-width-collapsed-background) {
        display: none;
    }

    @media print {
        display: none;
    }

    a {
        //font-weight: 400;
        font-size: $font-size-vertical-nav;
        width: 100%;
        font-weight: 400;
    }

    $offset-horizontal: 7px;
    $offset-vertical: 7px;

    margin: 0;
    padding: $vertical-nav-padding ($vertical-nav-padding - 5px) $vertical-nav-padding $vertical-nav-padding;
    //margin-top: 20px;

    >ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        //background-color: $color-vertical-nav-background;
        //$transition: all 0.2s ease-in-out;
        //$transition: all 0.4s ease-in-out 0.1s;
        $transition: all 0.4s linear;
        width: $vertical-nav-width;

        >li {
            padding: 0 $offset-horizontal;
            //border-bottom: 1px solid white;//$cl-dividers;//$color-vertical-nav-border;
            transition: $transition;
            position: relative;
            background-color: $cl-grey-100;

            margin-bottom: 3px;
            box-shadow: inset -1px -1px 18px -2px rgba(0, 0, 0, 0.06);

            &.external {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                background-color: $cl-grey-300; //$color-vertical-nav-external-link-background;

                .arrow,
                .text {
                    //border: 1px solid blue;
                    font-size: 12px;
                }


                .text {
                    padding: $offset-vertical 0 $offset-vertical $offset-horizontal;
                    margin-right: -300px;
                    padding-right: -300px;
                    //font-size: 0.7em;
                    //font-weight: $font-weight;
                    color: $cl-text-secondary; //$cl-text-primary-darkbg;//white;

                    .arrow {
                        //padding-left: $offset-horizontal;
                        font-size: 1.1em;
                        font-weight: $font-weight;
                        padding: 0;
                        //padding-bottom: 4px;
                        //padding-bottom: 2px;
                        height: 10px;
                    }
                }
            }

            &.header {
                $gray-scale: 0.77;
                background-color: $cl-grey-100; //rgb($gray-scale * 255, $gray-scale * 255, $gray-scale * 255);
            }

            >a {
                //color: $nav-category-font-color;
                color: $cl-text-primary; //black;
                font-weight: $font-weight;
                display: inline-block;
                //border: 1px solid black;
                padding: $offset-vertical 0 $offset-vertical $offset-horizontal;
                //border: 1px solid black;

                &.external {}

            }

            >.icon {
                //padding: 0px 10px 5px 10px;
                //padding: 8px 8px 15px 7px;
                padding: 5px 6px 12px 5px;
                line-height: 0px;
                cursor: pointer;
                color: $cl-text-primary; //rgba(0, 0, 0, 0.8);
                display: inline-block;
                position: absolute;
                right: 5px;
                top: 7px;
                font-size: 25px;
                font-weight: 300;
                $transition: all 0.2s ease-in-out;
                transition: $transition;
                -webkit-transition: $transition;
                /** Chrome & Safari **/
                -moz-transition: $transition;
                /** Firefox **/
                -o-transition: $transition;
                /** Opera **/
                border-radius: 100px;
                background: transparent; //$cl-grey-100;//rgba(255, 255, 255, 0.35);
                //border: 1px solid rgba(0, 0, 0, 0.3);

                $transform1: rotate(-90deg);
                transform: $transform1;
                -webkit-transform: $transform1;
                /** Chrome & Safari **/
                -o-transform: $transform1;
                /** Opera **/
                -moz-transform: $transform1;
                /** Firefox **/
                -webkit-transform-origin: 50% 50%;
                -moz-transform-origin: 50% 50%;
                -o-transform-origin: 50% 50%;
                transform-origin: 50% 50%;


                &:hover {
                    background: $nav-backgroud-color; //$cl-grey-300;//rgba(0, 0, 0, 0.5);
                    color: $cl-text-primary-darkbg; //white;
                }

                &.open {
                    color: $cl-text-primary-darkbg; //$color-vertical-nav-background;//$cl-text-primary-darkbg;//white;
                    $transform2: rotate(90deg);
                    transform: $transform2;
                    -webkit-transform: $transform2;
                    /** Chrome & Safari **/
                    -o-transform: $transform2;
                    /** Opera **/
                    -moz-transform: $transform2;
                    /** Firefox **/
                    -webkit-transform-origin: 50% 50%;
                    -moz-transform-origin: 50% 50%;
                    -o-transform-origin: 50% 50%;
                    transform-origin: 50% 50%;
                    background: transparent; //$cl-grey-100;//rgba(255, 255, 255, 0.2);

                    &:hover {
                        //background: $cl-grey-100;//rgba(255, 255, 255, 0.4);
                        //color: $cl-text-primary;
                        background-color: $cl-text-secondary-darkbg;
                        color: $cl-text-primary;
                    }
                }

            }

            //&.active {
            //    .icon {
            //        display: none;
            //    }
            //}

            .sub {
                transition: $transition;
                max-height: 0;
                //display: none;
                overflow: hidden;


                //display: inherit;
                //max-height: 200px;
                padding: 0;
                margin: 0;

                //border: 1px solid black;
                >a {
                    //border: 1px solid black;
                    color: $cl-text-secondary-darkbg; //$color-hierarchy-2;
                    padding: $offset-vertical 2 * $offset-horizontal;
                    display: inline-block;
                    //font-size: 1.1em;
                    font-weight: $font-weight;

                    &.is-no-link {
                        cursor: default;

                        &:hover {
                            text-decoration: none;

                        }
                    }

                    &.active {
                        color: $cl-text-primary-darkbg; //white;
                    }
                }

                ul {
                    list-style-type: none;
                    //border: 1px solid black;
                    margin: 0;
                    padding: 0;

                    >li {
                        padding: 0;
                        margin: 0;

                        >a {
                            color: $cl-text-secondary-darkbg; //$color-hierarchy-3;
                            padding: $offset-vertical 0 $offset-vertical 3 * $offset-horizontal;
                            display: inline-block;
                            font-weight: $font-weight;

                            &.active {
                                color: $cl-text-primary-darkbg; //white;
                            }
                        }
                    }
                }
            }

            &.open,
            &.active {
                background-color: $nav-backgroud-color;

                .sub {
                    &.menu-height-identifier-1 {
                        max-height: 260px;
                    }

                    &.menu-height-identifier-2 {
                        max-height: 400px;
                    }

                    &.menu-height-identifier-3 {
                        max-height: 300px;
                    }
                }
            }

            &.active {
                >a {
                    color: $cl-text-primary-darkbg;
                }
            }

            &.open {
                >a {
                    color: $cl-text-secondary-darkbg;
                }
            }
        }
    }
}